:root {
    --url:%REACT_APP_BASE_URL%
}
.page-login{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100vw;
    height:100vh;
    overflow: hidden;
    background-size: cover;
}

.page-login .ant-spin-nested-loading .ant-spin-blur::after{
    opacity: 0;
}