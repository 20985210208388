/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  font-family: 'Montserrat', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EAE6F0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
}

.ant-layout-content,
.ant-layout-footer {
  background-color: #EAE6F0 !important;
  background: #EAE6F0 !important;
}

.gelatine {
  animation: gelatine 0.5s 5;
}

.gelatine2 {
  animation: gelatine 0.5s infinite;
}

@keyframes gelatine {

  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

.foto-perfil {
 
  border-radius: 50%;
  overflow: hidden;
  background-color: whitesmoke;
  img{
    max-width: 100%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-title-content {
  font: normal normal bold 20px/36px Helvetica Neue;
  ;
}

.ant-layout-sider {
  width: 250px !important;
  max-width: 250px !important;
}

.content-page {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
}

.boton {
  border: none;
  background: #10C491 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000094;
  border-radius: 39px;
  font: normal normal medium 28px/42px Poppins;
  padding: 16px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.line {
  width: 70%;
  margin: 0 auto;
  height: 7px;
  /* UI Properties */
  background: #10C491 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-bottom: 32px;
}

.ant-typography {
  font-family: 'Montserrat', sans-serif;
}

.ant-btn-primary {
  box-shadow: 0px 3px 6px #00000094;
}

.inactive {
  opacity: .5;
}

.r1 {
  animation: r1 .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
}

@keyframes r1 {
  to {
    transform: scale(1);
  }
}

.ant-layout {
  background-color: white;
}

.ant-tabs-nav {
  margin-bottom: 4px !important;
  background-color: white;
  padding-top: 16px;
  padding-left: 16px;
}

.ant-tabs-content-top {
  /* border-top:1px solid #e8e5e5 */
}