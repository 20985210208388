* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: white;
  border-color: white;
  position: relative;
  display: inline-block;
}

.hex:before {
  content: " ";
  width: 0; height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}

.ql-toolbar.ql-snow{
  border:1px solid #d9d9d9!important;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.ql-container.ql-snow {
  border: 1px solid #d9d9d9!important;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.fondo {
  z-index: -1;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('/public/imagenes/fondo.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
/* .fondo::before{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top:0;
  left:0;
  content: "";
  filter: blur(10px);
} */

.titulo{
  font-family: 'Montserrat';
}
.ant-tree-node-selected{
  padding: 16px;
}
.ant-input {
  width: 100%;
}

.card-curso{
  box-shadow: none!important;
  background-color: inherit!important;
  
}
.card-curso .ant-tree{
  background: inherit!important;
}
.ant-collapse {
  background-color: transparent;
}
.ant-collapse-header{
  border-radius: 0 0 8px 8px
}
.ant-collapse-item:last-child{
  border-radius: 0 0 8px 8px
}


#contenedor-principal {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100px;
  position: relative;
}

#contenedor-contenido {
  width: 80%;
  min-height: 100px;
  /* background-color: #EBE6F0; */
  background-color: #fdfcfd;
  box-shadow: 0px 3px 10px #00000067;
  border: 1px solid #44088D;
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 24px;
}

.modal-leccion{
  .ant-modal-content{
    padding: 0;
  }
  
  .ant-modal-header,.ant-modal-footer{
    padding: 20px 24px;
  }
}
.item-principal {
  transition: border .1s;
  cursor: pointer;
  max-width: 100%;
  border-radius: 8px;
  padding: 24px;
  max-height: 88px;
  max-width: 292px;
  position: relative;
  box-sizing: border-box;
}

.item-principal:hover {
  border: 3px solid #FFFFFF;
  margin-bottom: 21px;
}

.item-principal.active {
  border: 3px solid #FFFFFF;
  margin-bottom: 21px;
}

.item-principal {
  margin-bottom: 24px;

  h2 {
    pointer-events: none;
    color: whitesmoke;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }

  img {
    pointer-events: none;
    width: 100%;
  }
}

.item-principal.proyecto {
  background: transparent linear-gradient(170deg, #5BBEFE 0%, #1792E2 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000015;

  img {
    content: url(/public/imagenes/rompecabezas.png);
    position: absolute;
    right: 16px;
    bottom: 0px;
    width: 30%;
  }
}

.item-principal.descubre {
  background: transparent linear-gradient(170deg, #3CC4C4 0%, #1BB7B7 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000015;

  img {
    content: url(/public/imagenes/foco.png);
    position: absolute;
    right: 16px;
    bottom: 0px;
    width: 30%;
  }
}

.item-principal.actividad {
  background: transparent linear-gradient(169deg, #FE779D 0%, #F4467B 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000015;

  img {
    content: url(/public/imagenes/calendar.png);
    position: absolute;
    right: 16px;
    bottom: 0px;
    width: 30%;
  }
}




.proyectos-bienvenida {
  background: transparent linear-gradient(124deg, #777CFE 0%, #5359F8 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000015;
  border-radius: 12px;
  padding: 24px;

  p,
  h2 {
    color: whitesmoke;
  }

  p {
    font-weight: 500;
    font-family: 'Roboto';
  }

  h2 {
    font-size: 33px/43px;
  }
}

.descubre-bienvenida {
  background: transparent linear-gradient(124deg, #3ACEAD 0%, #03B58D 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000015;
  border-radius: 12px;
  padding: 24px;

  p,
  h2 {
    color: whitesmoke;
  }

  .ant-input-wrapper {
    background: rgba(255, 255, 255, .3);
    box-shadow: 3px 3px 6px #00000010;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
  }

  .ant-input {
    border-radius: 8px;
    border: none;
    background-color: transparent;
    color: white
  }

  .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.468);
  }

  .ant-input-search-button {
    border-radius: 8px;
    border: none;
    background-color: transparent;
    color: white !important
  }

  p {
    font-weight: 500;
    font-family: 'Roboto';
  }

  h2 {
    font-size: 33px/43px;
  }
}

.pagina-descubre {
  .ant-statistic-content-value {
    color: #095544;
    font-family: 'Montserrat';
    font-weight: 500;
  }
}





.arduino-contenido {
  visibility: visible;
  direction: ltr;
  flex: 1;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid rgb(249, 249, 249);
  border-bottom: 1px solid rgb(249, 249, 249);
}

.ant-notification-notice-message {
  margin-inline-start: 96px !important
}

pre {
  overflow-x: auto;
  margin: 0;
}

pre.first::-webkit-scrollbar {
  background: #b3bccc;
}

pre.first::-webkit-scrollbar-thumb {
  background: #eee;
}

pre.first {
  background: #0b0b0b;
  color: whitesmoke;
  padding: 15px;
  font-size: 13pt;
  tab-size: 4;
}

.budgets {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  gap: 16px;
  justify-content: space-between;
  /* grid-template-columns: repeat(auto-fill, 200px);
  
  gap: 16px 16px; */
}

@media only screen and (max-width: 1024px) {
  .budgets {
    grid-template-columns: 50fr 50fr;
  }
}



/* 
@media only screen and (min-width: 1281px) {
  .budgets{
    grid-template-columns: repeat(4, 170px);
  }
}
@media only screen and (max-width: 1280px) {
  .budgets{
    grid-template-columns: repeat(3, 180px);
  }
}
@media only screen and (max-width: 1024px) {
  .budgets{
    grid-template-columns: repeat(3, 180px);
  }
}

 */

.sombra {
  box-shadow: 3px 3px 6px #00000010;
}

.budget {
  background-color: #f8f9f9;
  width: 100%;
  height: 100%;
  min-height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}



.budget h1 {
  font-size: 32px;
}

.budget:hover {
  cursor: pointer;
  animation: gelatine 0.5s;
}

.budget.sombra:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.budget {
  .titulo {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: .9em;
    font-family: 'Montserrat';
    text-overflow: ellipsis;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
  }

  .creador {
    font-weight: 500;
    color: #44088D;
    font-size: .8em;
  }

  .tiempo {
    font-size: .5em;
    color: #A3A3A3
  }

  .contenedor-avatar {
    position: relative;

    span.Estudiante {
      background-image: url('https://cdn-icons-png.flaticon.com/512/1157/1157034.png');
      background-size: cover;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      top: -16px;
      right: 0;
    }
  }
}

.ant-layout-header {
  z-index: 9999;
  position: relative;
}